/* Configure Default Camera */
// Converted version of example:
// https://github.com/mrdoob/three.js/blob/master/examples/webgl_postprocessing_rgb_halftone.html

import { useState, useEffect, useRef } from "react"
import useRaf from "@rooks/use-raf"
import * as THREE from "three"
import {
  EffectComposer,
  RenderPass,
} from "../three/postProcessing/postProcessing"

// import global hooks
import useRenderer from "~hooks/useRenderer"
import usePlayheadPosition from "~hooks/usePlayheadPosition"
import { useWindowSize } from "react-use"

// import higher order components
import withComposition from "~higherOrderComponents/withComposition"

const ExampleComposition = ({ startTime, endTime }) => {
  // State
  const [isReady, setIsReady] = useState(false)

  // Hooks
  const playheadPosition = usePlayheadPosition()
  const renderer = useRenderer()
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  // References
  const cameraReference = useRef()
  const sceneReference = useRef()
  const clockReference = useRef()
  const effectComposerReference = useRef()

  // Constants
  const isActive =
    (!startTime && !endTime) ||
    (playheadPosition >= startTime && playheadPosition <= endTime)

  const handleResize = () => {
    // constants
    const camera = cameraReference.current
    camera.aspect = windowWidth / windowHeight
    camera.updateProjectionMatrix()
  }

  useEffect(() => {
    /* Constants and References */
    cameraReference.current = new THREE.PerspectiveCamera(
      75,
      windowWidth / windowHeight,
      0.1,
      1000
    )
    sceneReference.current = new THREE.Scene()
    clockReference.current = new THREE.Clock()
    effectComposerReference.current = new EffectComposer(renderer)
    const camera = cameraReference.current
    const scene = sceneReference.current
    const effectComposer = effectComposerReference.current
    /* Configure Camera */
    camera.position.z = 3
    window.addEventListener("resize", handleResize)

    /* Configure Scene */
    const geometry = new THREE.BoxGeometry(1, 1, 1)
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    const cube = new THREE.Mesh(geometry, material)
    cube.name = "cube"
    scene.add(cube)

    /* Configure Effect Composer */
    effectComposer.addPass(new RenderPass(scene, camera))

    /* Done Configuring Scene */
    setIsReady(true)
  }, [])

  /* Configure Update Function */
  const update = () => {
    // constants
    const scene = sceneReference.current
    // update
    const radiansPerSecond = THREE.MathUtils.degToRad(30)
    const delta = clockReference.current.getDelta()
    const cube = scene.getObjectByName("cube", true)
    cube.rotation.x += radiansPerSecond * delta
    cube.rotation.y += radiansPerSecond * delta
  }

  // Request Animation Frame
  useRaf(() => {
    update()
    // constants
    const effectComposer = effectComposerReference.current
    effectComposer.render()
  }, isReady && isActive)

  return null
}

const ExampleCompositionWithComposition = withComposition(ExampleComposition)

export default ExampleCompositionWithComposition
